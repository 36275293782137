<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>disability-hearing-aid</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M.75,18a5.25,5.25,0,0,0,10.5,0,5.314,5.314,0,0,1,1.865-4.042A7.5,7.5,0,1,0,.75,8.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M11.25,9a3.75,3.75,0,1,0-7.256,1.333"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="6" cy="13.5" r="3.75" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M21.841,13.089a11.26,11.26,0,0,0,0-10.908"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M19.479,4.5a6.767,6.767,0,0,1,0,6.274"
    />
  </svg>
</template>
